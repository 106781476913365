(function($){

  var acc = {

    init: function(){

      acc.navTrigger();
      acc.markActiveNav();

      if($('#contact-form').length){
        acc.contactForm();
      }
      if($('#sign-form').length){
        acc.newsletterForm();
      }
      if($('.fadein').length){
        acc.loadFade();
      }
      if($('.bio-shade').length){
        acc.speakerBios();
      }
      if($('.three-col').length){
        acc.threeColFormat();
      }
      // if($('.purchase-button').length){
      //   acc.purchase();
      // }
    },
    markActiveNav: function(){

      var thispage = $('body').data('page');
      if(thispage != ''){
        $('.nav-link[data-pagename="' +thispage+ '"]').addClass('current');
      }

    },
    loadFade: function(){

      $('.fadein').each(function(){
        var fade = $(this);

        if(isInViewport(fade)){
          if($(this).hasClass('lazy-image')){
            var thisurl = $(this).data('lazyurl');
            $(this).attr('src', thisurl);

            setTimeout(function(){
              fade.addClass('visible');
            },500);
          } else {
            fade.addClass('visible');
          }
        }

      });

      function isInViewport(element) {
        const rect = element[0].getBoundingClientRect();
        return (
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
      }      

    },
    navTrigger: function(){

      $('.nav-trigger').on('click', function(){
        $('body').addClass('nav-open');
      });
      $('.nav-close').on('click', function(){
        $('body').removeClass('nav-open');
      });

    },
    contactForm: function(){

      $('#contact-form').submit(function(e){
        e.preventDefault();

        var first = $('#contact-form input#first_name').val();
        var last = $('#contact-form input#last_name').val();
        var email = $('#contact-form input#email').val();
        var message = $('#contact-form textarea#message').val();
        var zip = $('#contact-form input#zip').val();

        $.post('/sendmessage?first='+first +'&last='+last +'&email='+email +'&zip='+zip, { message: message }, function(data){ });
      });
    },
    newsletterForm: function(){

      $('#sign-form').submit(function(e){
        e.preventDefault();
        var email = $('#sign-form input#email').val();
        var zip = $('#sign-form input#zip').val();
        $.post('/newsignup?email='+email +'&zip='+zip, function(data){ });
      });

    },
    speakerBios: function(){

      $('.speaker-name').on('click', function(){
        $('body').addClass('bio-shade-open');
        $(this).closest('.speaker').addClass('bio-active');
      });

      $('.bio-shade').on('click', function(){
        $('body').removeClass('bio-shade-open');
        $(this).closest('.speaker').removeClass('bio-active');
      });

    },
    purchase: function(){
      $('.purchase-button').on('click', function(){
        var thisitem = $(this).data('product');
        $.post('/purchase?item='+thisitem, function(data){ });
      });
    },
    threeColFormat: function(){
      $('h2').each(function(){
        $(this).next('ul').addBack().wrapAll('<div class="col"></div>');
      });
    }

  };


  $(window).on('load', function(){

    acc.init();

  });

  document.addEventListener('scroll', function () {
    acc.loadFade();
  });


})(window.jQuery);
